import React from "react";
import styled from "styled-components";
import image1 from "../assets/extras/extra1.jpg";
import image2 from "../assets/extras/extra2.jpg";
import image3 from "../assets/extras/extra3.png";

const Wrapper = styled.div`
  max-width: 1500px; /* Limit the maximum width to 1500px */
  margin: 0 auto; /* Center the container */
  padding: 0 50px; /* Keep 50px padding on both sides */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
  width: calc(100% - 100px); /* Make sure it takes 50px padding into account */
`;

const Container = styled.div`
  display: flex;
  height: 120px;
  justify-content: space-between;
  width: 100%; /* Ensure it takes full width of the Wrapper */
  margin-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on mobile */
  }
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    &:nth-child(n + 2) {
      display: none; /* Hide all columns except the first one on mobile */
    }
  }
`;

const SubColumnLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubColumnRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const Title = styled.h2`
  margin: 10px 0;
  font-size: 16px;
`;

const Subtitle = styled.h4`
  margin: 0;
  font-size: 14px;
  color: #555;
`;

const Link = styled.a`
  margin-top: 15px;
  text-decoration: underline;
  color: black;
  font-family: Times Now;
`;

const DiscoverSection = () => {
  return (
    <Wrapper>
      <Container>
        <MainColumn>
          <SubColumnLeft>
            <Image src={image1} alt="app" />
          </SubColumnLeft>
          <SubColumnRight>
            <Title>THE OMI APP</Title>
            <Subtitle>The one and only AI stylist</Subtitle>
            <Link href="#">Download now</Link>
          </SubColumnRight>
        </MainColumn>
        <MainColumn>
          <SubColumnLeft>
            <Image src={image2} alt="health" />
          </SubColumnLeft>
          <SubColumnRight>
            <Title>FOR HIM</Title>
            <Subtitle>Discover OMIS Health in mind</Subtitle>
            <Link href="#">Discover more</Link>
          </SubColumnRight>
        </MainColumn>
        <MainColumn>
          <SubColumnLeft>
            <Image src={image3} alt="resell" />
          </SubColumnLeft>
          <SubColumnRight>
            <Title>OMIS RESELL</Title>
            <Subtitle>Give your clothes a new life</Subtitle>
            <Link href="#">Resell now</Link>
          </SubColumnRight>
        </MainColumn>
      </Container>
    </Wrapper>
  );
};

export default DiscoverSection;
