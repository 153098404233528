import React, { useEffect, useState } from "react";
import axios from "axios";

const ItemDistributionTable = () => {
  const [maleItemsCount, setMaleItemsCount] = useState({});
  const [femaleItemsCount, setFemaleItemsCount] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchAllClothingItems = async (page = 1, allItems = []) => {
    try {
      const response = await axios.get(
        `https://strapi-production-7b58.up.railway.app/api/clothing-items`,
        {
          params: {
            pagination: {
              page: page,
              pageSize: 100, // Adjust this value as needed (max 100 for Strapi)
            },
          },
        }
      );
      const clothingItems = response.data.data;

      // Accumulate all items
      const updatedItems = [...allItems, ...clothingItems];

      // Check if there are more pages to fetch
      const { pageCount } = response.data.meta.pagination;
      if (page < pageCount) {
        return fetchAllClothingItems(page + 1, updatedItems); // Fetch next page
      }

      return updatedItems; // Return all accumulated items when done
    } catch (error) {
      console.error("Error fetching clothing items:", error);
      return allItems;
    }
  };

  useEffect(() => {
    const getClothingItems = async () => {
      const clothingItems = await fetchAllClothingItems();

      const maleCount = {};
      const femaleCount = {};

      clothingItems.forEach((item) => {
        const { clothingType, gender } = item.attributes;
        if (gender === "male") {
          maleCount[clothingType] = (maleCount[clothingType] || 0) + 1;
        } else if (gender === "female") {
          femaleCount[clothingType] = (femaleCount[clothingType] || 0) + 1;
        }
      });

      setMaleItemsCount(maleCount);
      setFemaleItemsCount(femaleCount);
      setLoading(false);
    };

    getClothingItems();
  }, []);

  const renderTable = (title, itemsCount) => {
    // Calculate the total count
    const totalCount = Object.values(itemsCount).reduce(
      (acc, count) => acc + count,
      0
    );

    return (
      <div style={{ margin: "0 20px" }}>
        <h3>{title}</h3>
        <table border="1" cellPadding="8">
          <thead>
            <tr>
              <th>Clothing Type</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(itemsCount).map((type) => (
              <tr key={type}>
                <td>{type}</td>
                <td>{itemsCount[type]}</td>
              </tr>
            ))}
            <tr style={{ color: "red" }}>
              <td>
                <strong>Total</strong>
              </td>
              <td>
                <strong>{totalCount}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start", // Align tables at the top
        padding: "20px",
      }}
    >
      {renderTable("Male Clothing Items", maleItemsCount)}
      {renderTable("Female Clothing Items", femaleItemsCount)}
    </div>
  );
};

export default ItemDistributionTable;
