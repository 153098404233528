import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StylistChat from "./components/stylist-chat";
import "react-chat-elements/dist/main.css";

// routes
import HomePage from "./pages/homepage";
import ItemsPage from "./pages/items-page"; //test old and new
import ItemDetails from "./pages/item-details-page";
import WishlistPage from "./pages/wishlist-page";
import BagPage from "./pages/bag-page";
import ItemDistributionTable from "./pages/item-distribution-table";

function App() {
  return (
    <div className="App">
      <Router>
        {/* page routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/items" element={<ItemsPage gender={null} />} /> */}
          <Route path="/items-men" element={<ItemsPage gender="male" />} />
          <Route path="/items-women" element={<ItemsPage gender="female" />} />
          <Route path="/item-details/:id" element={<ItemDetails />} />
          <Route path="/wishlist" element={<WishlistPage />} />
          <Route path="/bag" element={<BagPage />} />
          <Route
            path="/item-table-distribution"
            element={<ItemDistributionTable />}
          />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
      <StylistChat />
    </div>
  );
}

export default App;
