import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import FooterNewsletter from "../components/footer-newsletter";
import axios from "axios";
import MenuBar from "../components/menu-bar";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 2rem;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1125px;
  width: 100%;
  padding: 2rem;
  background-color: white;
`;

export const ImageWrapper = styled.div`
  flex: 0 0 40%;
  margin-right: 2rem;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const ThumbnailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Thumbnail = styled.div`
  flex: 0 0 calc(20% - 10px); /* 20% width for 5 items in a row */
  max-width: calc(
    20% - 10px
  ); /* Ensure each thumbnail takes 20% of the width */
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid black;
    box-sizing: border-box;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-family: TimesNow-Light;
  font-weight: 400;
  font-size: 34px;
  margin-bottom: 0px;
  text-align: left;
`;

export const Subtitle = styled.h3`
  font-family: TimesNow-SemiBold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: left;
  color: #555;
`;

export const Description = styled.p`
  margin-bottom: 1rem;
  text-align: left;
`;

export const Price = styled.p`
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
`;

export const SizeOptions = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1rem;
`;

export const SizeButton = styled.button`
  padding: 10px;
  border: 1px solid #ccc;
  background-color: ${(props) => (props.selected ? "#f0f0f0" : "white")};
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const BagButton = styled.button`
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  width: 400px;
  height: 45px;
  &:hover {
    background-color: #333;
  }
`;

export const WishlistButton = styled.button`
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid #000; // Added border for visibility
  cursor: pointer;
  background-color: white;
  color: #000; // Changed to black for visibility
  width: 400px;
  height: 45px;

  &:hover {
    background-color: #f0f0f0; // Light gray on hover
  }
`;

export const EditorsNotes = styled.div`
  font-family: TimesNow;
  font-size: 18px;
  line-height: 25px;
  margin-top: 50px;
  text-align: left;
`;

export const EditorsNotesLabel = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
`;

export const EditorsNotesContent = styled.p`
  text-align: left;
`;

const ItemDetailsPage = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // New state for selected image
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [isInBag, setIsInBag] = useState(false);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://strapi-production-7b58.up.railway.app/api/clothing-items/${id}`,
          {
            params: {
              populate: "*",
            },
          }
        );

        const fetchedItem = response.data.data;
        setItem({
          id: fetchedItem.id,
          brand: fetchedItem.attributes.brand,
          title: fetchedItem.attributes.title,
          price: fetchedItem.attributes.price,
          description: fetchedItem.attributes.description,
          images: fetchedItem.attributes.images.data.map(
            (img) => img.attributes.url
          ),
        });
        setSelectedImage(fetchedItem.attributes.images.data[0].attributes.url); // Set initial image
        setLoading(false);
      } catch (error) {
        console.error("Error fetching item details:", error);
        setError("Failed to load item details. Please try again later.");
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [id]);

  useEffect(() => {
    const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    setIsInWishlist(wishlist.includes(id));
  }, [id]);

  useEffect(() => {
    const bag = JSON.parse(localStorage.getItem("bag")) || [];
    setIsInBag(bag.includes(id));
  }, [id]);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  const handleWishlistToggle = () => {
    let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    if (isInWishlist) {
      wishlist = wishlist.filter((wishlistId) => wishlistId !== id);
    } else {
      wishlist.push(id);
    }
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
    setIsInWishlist(!isInWishlist);
  };

  const handleBagToggle = () => {
    let bag = JSON.parse(localStorage.getItem("bag")) || [];
    if (isInBag) {
      bag = bag.filter((bagId) => bagId !== id);
    } else {
      bag.push(id);
    }
    localStorage.setItem("bag", JSON.stringify(bag));
    setIsInBag(!isInBag);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the clicked image as the main image
  };

  if (loading) {
    return (
      <CenteredContainer>
        <p>Loading...</p>
      </CenteredContainer>
    );
  }

  if (error) {
    return (
      <CenteredContainer>
        <p>{error}</p>
      </CenteredContainer>
    );
  }

  if (!item) {
    return (
      <CenteredContainer>
        <p>Item not found.</p>
      </CenteredContainer>
    );
  }

  return (
    <PageWrapper>
      <MenuBar />
      <CenteredContainer>
        <Container>
          <ImageWrapper>
            <img src={selectedImage} alt={item.title} />
            <ThumbnailWrapper>
              {item.images.map((image, index) => (
                <Thumbnail
                  key={index}
                  onClick={() => handleImageClick(image)}
                  selected={selectedImage === image}
                >
                  <img src={image} alt={`Thumbnail ${index}`} />
                </Thumbnail>
              ))}
            </ThumbnailWrapper>
          </ImageWrapper>
          <ContentWrapper>
            <Title>{item.brand}</Title>
            <Subtitle>{item.title}</Subtitle>
            <Price>${item.price}</Price>

            <SizeOptions>
              {["S", "M", "L", "XL"].map((size) => (
                <SizeButton
                  key={size}
                  selected={selectedSize === size}
                  onClick={() => handleSizeSelect(size)}
                >
                  {size}
                </SizeButton>
              ))}
            </SizeOptions>

            <ActionButtons>
              <BagButton onClick={handleBagToggle}>
                {isInBag ? "Remove from Bag" : "Add to Bag"}
              </BagButton>
              <WishlistButton onClick={handleWishlistToggle}>
                {isInWishlist ? "Remove from Wishlist" : "Add to Wishlist"}
              </WishlistButton>
            </ActionButtons>

            <EditorsNotes>
              <EditorsNotesLabel>Editor's Notes</EditorsNotesLabel>
              <EditorsNotesContent>{item.description}</EditorsNotesContent>
            </EditorsNotes>
          </ContentWrapper>
        </Container>
      </CenteredContainer>
      <FooterNewsletter />
    </PageWrapper>
  );
};

export default ItemDetailsPage;
