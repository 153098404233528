import React, { useState, useEffect } from "react";
import styled from "styled-components";
import OmiLogo from "../assets/logos/omi_seattle_logo_white_big.png";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Stick the message box to the bottom */
  z-index: 9999;
`;

const MessageBox = styled.div`
  width: 100%; /* Make it full width */
  background-color: #111111;
  padding: 50px 300px;
  letter-spacing: 1px;
  color: white;
  text-align: start;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start */
  align-items: flex-start; /* Align items to the start */
  z-index: 1001;
`;

const Logo = styled.img`
  height: 30px;
  margin-bottom: 20px; /* Space below the logo */
`;

const MessageText = styled.p`
  font-size: 14px;

  line-height: 1.45;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start; /* Align buttons to the start */
  gap: 20px; /* Space between the two buttons */
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ffffff;
  color: #111111;
  border: none;
  cursor: pointer;
  font-weight: bold;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Disclaimer = () => {
  // Check localStorage for prior acceptance
  const [showMessage, setShowMessage] = useState(
    !localStorage.getItem("disclaimerAccepted")
  );

  useEffect(() => {
    // Disable scrolling when the overlay is shown
    if (showMessage) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto"; // Restore scrolling
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = "auto"; // Ensure scrolling is restored
    };
  }, [showMessage]);

  const handleClose = () => {
    setShowMessage(false);
  };

  const handleAction = (action) => {
    if (action === "accept") {
      console.log("Accepted");
      localStorage.setItem("disclaimerAccepted", "true"); // Save acceptance status
    } else {
      console.log("Declined");
    }
    handleClose();
  };

  return (
    <>
      {showMessage && (
        <Overlay>
          <MessageBox>
            <Logo src={OmiLogo} alt="Omi Logo" />
            <MessageText>
              This is a mock website created for demonstration purposes only.
              Users cannot purchase any items through this site. The purpose of
              this website is to showcase Omi's capabilities and how it can help
              retailers and brands engage with their customers in new and
              innovative ways. We collect a very limited amount of data solely
              for demonstration purposes and do not retain any personal
              information.
            </MessageText>
            <ButtonContainer>
              <Button onClick={() => handleAction("accept")}>Accept All</Button>
              <Button onClick={() => handleAction("decline")}>
                Learn More
              </Button>
            </ButtonContainer>
          </MessageBox>
        </Overlay>
      )}
    </>
  );
};

export default Disclaimer;
