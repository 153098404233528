import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SearchBarOverlay from "./search-bar-overlay";
import OmiLogo from "../assets/logos/omi_seattle_logo_white.svg";

const Bar = styled.nav`
  height: 65px;
  background-color: #111111;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 20px;
  @media (max-width: 768px) {
    visibility: hidden;
    height: 0px;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const Logo = styled.img`
  height: 30px;
`;

const MenuList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  margin: 0 15px;
  margin-top: 25px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const MenuLink = styled(Link)`
  font-family: "PPNeueMontreal-Book" !;
  letter-spacing: 0.7px;
  text-decoration: none;
  color: white;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

const leftMenuItems = [
  { name: "MENSWEAR", path: "/items-men" },
  { name: "WOMENSWEAR", path: "/items-women" },
  { name: "SALE", path: "/item-table-distribution" },
];

const rightMenuItems = [
  { name: "LOGIN", path: "#" },
  { name: "WISHLIST", path: "/wishlist" }, // Updated the path for WISHLIST
  { name: "BAG (0)", path: "/bag" },
];

const MenuBar = ({ setSearchTerm }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [bagCount, setBagCount] = useState(0); // State to track bag count

  // Function to update bag count
  const updateBagCount = () => {
    const bag = JSON.parse(localStorage.getItem("bag")) || [];
    setBagCount(bag.length); // Set the count based on the length of the bag array
  };

  // Effect to update bag count on mount
  useEffect(() => {
    updateBagCount(); // Initial load
  }, []);

  return (
    <>
      <Bar>
        {/* Left-aligned menu items */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuList>
            {leftMenuItems.map((item, index) => (
              <MenuItem key={index}>
                <MenuLink to={item.path}>{item.name}</MenuLink>
              </MenuItem>
            ))}

            <MenuItem>
              <MenuLink>SEARCH</MenuLink>
            </MenuItem>
          </MenuList>
        </div>

        {/* Centered logo */}
        <LogoWrapper>
          <a href="/">
            <Logo src={OmiLogo} alt="Omi Logo" />
          </a>
        </LogoWrapper>

        {/* Right-aligned menu items */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuList>
            {rightMenuItems.map((item, index) => (
              <MenuItem key={index}>
                <MenuLink
                  to={item.path}
                  onClick={(e) => item.path === "#" && e.preventDefault()}
                >
                  {item.name === "BAG (0)"
                    ? `BAG (${bagCount})` // Update the bag count dynamically
                    : item.name}
                </MenuLink>
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Bar>
    </>
  );
};

export default MenuBar;
