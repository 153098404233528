import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchHeroImage } from "../services/strapi-calls";

const ImageContainer = styled.div`
  width: 100%;
  height: calc(
    100vh - 180px
  ); // Subtracting the total height of previous elements
  background-color: gray; // Placeholder color
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden; // Ensure the image doesn't exceed the container
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; // Ensures the image covers the container while maintaining its aspect ratio
  object-position: center;
`;

//overlay
const styles = {
  container: {
    position: "absolute",
    bottom: "30px",
    left: "50px", // This will be overridden by the media query for mobile
    padding: "20px",
    maxWidth: "800px",
    color: "#fff",
    textAlign: "left",
    boxSizing: "border-box",
  },
  title: {
    fontFamily: "PPNeueMontreal-Thin !",
    fontWeight: 600,
    fontSize: "34px",
    marginBottom: "5px",
    color: "black",
    letterSpacing: "1px",
    textShadow: "1px 1px 1px rgba(255, 255, 255, 1)", // Slight white shadow
  },

  subtitle: {
    fontSize: "18px",
    margin: "10px 0",
    marginBottom: "20px",
    fontFamily: "Times Now",
    color: "black",
    textShadow: "1px 1px 1px rgba(255, 255, 255, 1)", // Slight white shadow
  },

  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "white",
    color: "#000",
    border: "none",
    cursor: "pointer",
    width: "250px",
    //width: "100%", // Ensures button fits within the container on mobile
    boxSizing: "border-box",
    height: "45px",
  },
  mediaQueries: `
    @media (max-width: 768px) {
      .overlay-container {
        left: 10px !important; /* Override the inline left value */
        right: 10px; /* Ensures container stops 10px from the right */
        padding: 10px;
      }
      .overlay-button {
        width: calc(100% - 20px); /* Adjusts button to fit within container */
      }
    }

    @media (min-width: 769px) {
      .overlay-button {
        width: 45%;
      }
    }

  `,
};

const HeroImage = () => {
  const [heroData, setHeroData] = useState({
    heroImageUrl: "",
    title: "",
    subtitle: "",
  });

  useEffect(() => {
    const loadHeroImage = async () => {
      try {
        const data = await fetchHeroImage();
        setHeroData({
          heroImageUrl: data.attributes.image.data.attributes.url,
          title: data.attributes.title,
          subtitle: data.attributes.subtitle,
        });
      } catch (error) {
        console.error("Error loading hero data:", error);
      }
    };

    loadHeroImage();
  }, []);

  return (
    <ImageContainer>
      {heroData.heroImageUrl && (
        <StyledImage src={heroData.heroImageUrl} alt="Hero" />
      )}
      <div>
        <div className="overlay-container" style={styles.container}>
          <h1 style={styles.title}>{heroData.title}</h1>
          <p style={styles.subtitle}>{heroData.subtitle}</p>
          <button className="overlay-button" style={styles.button}>
            Shop Now
          </button>
        </div>
      </div>
    </ImageContainer>
  );
};

export default HeroImage;
