import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { FaSearch, FaTimes } from "react-icons/fa";

// Animation for fading in the search bar
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Overlay background
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Black transparent overlay */
  z-index: 999; /* Ensure it appears above everything else */
  animation: ${fadeIn} 0.3s ease; /* Apply the fade-in animation */
`;

// Search bar container
const SearchBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  z-index: 1000; /* Make sure it appears above the overlay */
  display: flex;
  align-items: center;
  padding-left: 20px;
  animation: ${fadeIn} 0.3s ease; /* Apply the fade-in animation */
`;

// Search icon
const SearchIcon = styled(FaSearch)`
  color: grey;
  margin-right: 10px;
`;

// Input field
const SearchInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  color: grey;
  flex: 1;
`;

// Clear icon (X)
const ClearIcon = styled(FaTimes)`
  color: grey;
  cursor: pointer;
  margin-right: 40px;
`;

const SearchBarOverlay = ({ onClose, onSearch }) => {
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSearch = () => {
    onSearch(searchText);
    onClose();
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <SearchBarContainer>
        <SearchIcon />
        <SearchInput
          ref={inputRef}
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSearch()} // Trigger search on Enter key
        />
        {searchText && <ClearIcon onClick={() => setSearchText("")} />}
      </SearchBarContainer>
    </>
  );
};

export default SearchBarOverlay;
