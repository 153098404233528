import React from "react";
import styled from "styled-components";
import VisaLogo from "../assets/cards/visa.svg";
import PaypalLogo from "../assets/cards/paypal.svg";
import ApplePay from "../assets/cards/apple-pay.svg";
import Amex from "../assets/cards/amex.svg";
import Mastercard from "../assets/cards/mastercard.svg";
import Discover from "../assets/cards/discover.svg";
import DummyQr from "../assets/extras/dummy-qr.png";

const FooterContainer = styled.div`
  background-color: white; /* Adjust background color as needed */
  padding: 50px 0; /* Ensure top and bottom padding */
`;

const Wrapper = styled.div`
  max-width: 1500px; /* Stop resizing beyond 1500px */
  margin: 0 auto; /* Center the content */
  padding: 0 50px; /* Maintain 50px padding on both sides */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
`;

const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left; /* Align all text to the left */
`;

const Column = styled.div`
  flex: 1;
  margin: 10px 20px;
  min-width: 200px; /* Ensure columns don't get too narrow */
`;

const Title = styled.h4`
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: 1.15px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: 15px;
  font-size: 12px;
  font-family: PPNeueMontreal-Medium;

  a {
    color: #565656;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const QRCode = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
`;

const PaymentIcons = styled.div`
  margin-top: 20px;

  img {
    margin-right: 10px;
    width: 40px;
    height: auto;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <ColumnsContainer>
          <Column>
            <Title>CUSTOMER CARE</Title>
            <List>
              <ListItem>
                <a href="#">Track An Order</a>
              </ListItem>
              <ListItem>
                <a href="#">Create A Return</a>
              </ListItem>
              <ListItem>
                <a href="#">Contact Us</a>
              </ListItem>
              <ListItem>
                <a href="#">Exchanges & Returns</a>
              </ListItem>
              <ListItem>
                <a href="#">Delivery</a>
              </ListItem>
              <ListItem>
                <a href="#">Omi Premier</a>
              </ListItem>
              <ListItem>
                <a href="#">Terms & Conditions</a>
              </ListItem>
              <ListItem>
                <a href="#">Privacy Policy</a>
              </ListItem>
              <ListItem>
                <a href="#">California Privacy Rights</a>
              </ListItem>
              <ListItem>
                <a href="#">Do Not Sell Or Share My Personal Information</a>
              </ListItem>
              <ListItem>
                <a href="#">Cookie Policy</a>
              </ListItem>
            </List>
          </Column>
          <Column>
            <Title>ABOUT US</Title>
            <List>
              <ListItem>
                <a href="#">Discover Omi</a>
              </ListItem>
              <ListItem>
                <a href="#">Our Pledge</a>
              </ListItem>
              <ListItem>
                <a href="#">Omi Resell</a>
              </ListItem>
              <ListItem>
                <a href="#">Omi Health In Mind</a>
              </ListItem>
              <ListItem>
                <a href="#">Advertising</a>
              </ListItem>
              <ListItem>
                <a href="#">Omi Rewards</a>
              </ListItem>
              <ListItem>
                <a href="#">Affiliates</a>
              </ListItem>
              <ListItem>
                <a href="#">Careers</a>
              </ListItem>
              <ListItem>
                <a href="#">Our Apps</a>
              </ListItem>
              <ListItem>
                <a href="#">Modern Slavery Statement</a>
              </ListItem>
            </List>
          </Column>
          <Column>
            <Title>GET THE OMI APP</Title>
            <QRCode src={DummyQr} alt="QR Code" />
            <a
              href="#"
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                display: "block",
                marginBottom: "20px",
              }}
            >
              Shop on Omi
            </a>
            <Title>AFFIRM PAYMENT</Title>
            <p
              style={{
                fontSize: "13px",
                marginBottom: "10px",
                fontFamily: "PPNeueMontreal-Medium",
                color: "#565656",
                lineHeight: 1.35,
              }}
            >
              Payment options through Affirm are subject to an eligibility check
              and are provided by these lending partners:{" "}
              <a href="#" style={{ textDecoration: "underline" }}>
                affirm.com/lenders
              </a>
              . CA residents: Loans by Affirm Loan Services, LLC are made or
              arranged pursuant to a California Finance Lenders Law license.
            </p>
            <a
              href="#"
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                display: "block",
                marginBottom: "20px",
              }}
            >
              Learn more
            </a>
            <Title>OMI ACCEPTS</Title>
            <PaymentIcons>
              <img src={VisaLogo} alt="Visa" />
              <img src={Mastercard} alt="MasterCard" />
              <img src={Amex} alt="Amex" />
              <img src={ApplePay} alt="Apple Pay" />
              <img src={PaypalLogo} alt="PayPal" />
              <img src={Discover} alt="Discover" />
            </PaymentIcons>
          </Column>
        </ColumnsContainer>
      </Wrapper>
    </FooterContainer>
  );
};

export default Footer;
