import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px;
  background-color: #f5f5f5;
  height: 300px;

  @media (max-width: 768px) {
    height: 400px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack the columns vertically on mobile screens */
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
`;

const LeftSection = styled.div`
  flex: 1;
  max-width: 600px;
  text-align: left;
  margin-bottom: 20px; /* Add margin on mobile to space out the sections */
  margin-right: 50px;
`;

const RightSection = styled.div`
  flex: 1;
  max-width: 400px;
  text-align: left;
`;

const Title = styled.h4`
  font-family: Times Now;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0px;
`;

const Text = styled.p`
  font-family: TimesNow-Book;
  line-height: 22px;
  color: #565656;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  flex: 1;
  margin-right: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: black;
  border: 1px solid black;
  cursor: pointer;
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.a`
  margin-right: 15px;
  font-size: 24px;
  color: black;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #555;
  }
`;

// Styled link for consistent styling across the component
const StyledLink = styled.a`
  color: black; // Make the link text color black
  text-decoration: underline; // Keep the underline for links
`;

const FooterNewsletter = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <LeftSection>
          <Title>For Creators, Retailers and Brands</Title>
          <Text>
            If you enjoyed your introduction to the Omi Stylist experience, we
            invite you to drop your email below for more information on how Omi
            can enhance your e-commerce solution.{" "}
            <StyledLink href="#">Terms and conditions</StyledLink> apply.
          </Text>
          <Form>
            <Input type="email" placeholder="your@address.com" />
            <Button type="submit">Submit</Button>
          </Form>
          <Title>Links</Title>
          <Text>
            <StyledLink href="#">Privacy Policies</StyledLink>
            <br />
            <StyledLink href="#">Terms & Conditions</StyledLink>.
          </Text>
        </LeftSection>
        <RightSection>
          <Text>
            For any other request, please email us at{" "}
            <StyledLink href="#">info@omi.fit</StyledLink>.
          </Text>
          <Title>Change Location</Title>
          <Text>
            <StyledLink href="#">
              <img
                src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                alt="United States"
                style={{
                  width: "20px",
                  marginRight: "8px",
                  verticalAlign: "middle",
                }}
              />
              United States
            </StyledLink>
          </Text>
        </RightSection>
      </Wrapper>
    </FooterContainer>
  );
};

export default FooterNewsletter;
