// src/services/strapiService.js
import axios from "axios";

const API_URL = "https://strapi-production-7b58.up.railway.app/api";

export const fetchHeroImage = async () => {
  try {
    const response = await axios.get(`${API_URL}/hero-image?populate=*`); // Populate the image field
    return response.data.data; // Strapi returns the content under data.data
  } catch (error) {
    console.error("Error fetching hero image from Strapi:", error);
    throw error;
  }
};

//ITEMS
//*****************************************************************************************************************
// export const fetchClothingItems = async () => {
//   try {
//     const response = await axios.get(
//       "https://strapi-production-7b58.up.railway.app/api/clothing-items",
//       {
//         params: {
//           populate: "*",
//           "pagination[pageSize]": 1000, // Adjust this number if needed
//           "pagination[page]": 1, // Start from the first page
//         },
//       }
//     );
//     console.log(response.headers);
//     console.log(response.data.meta.pagination); // Look for pagination metadata in the response

//     return response.data.data; // Return the data directly
//   } catch (error) {
//     console.error("Error fetching clothing items:", error);
//     throw error; // Propagate the error for handling upstream
//   }
// };

export const fetchClothingItems = async () => {
  let allItems = [];
  let currentPage = 1;
  let totalPages = 1; // We'll get this from the response later
  const pageSize = 100; // Keep it as 100 since the API restricts it

  try {
    do {
      const response = await axios.get(
        "https://strapi-production-7b58.up.railway.app/api/clothing-items",
        {
          params: {
            populate: "*",
            "pagination[pageSize]": pageSize,
            "pagination[page]": currentPage,
          },
        }
      );

      const items = response.data.data;
      const meta = response.data.meta.pagination;

      allItems = [...allItems, ...items]; // Accumulate items
      totalPages = meta.pageCount; // Get total pages from the first request
      currentPage++; // Move to the next page
    } while (currentPage <= totalPages); // Keep fetching until all pages are done

    return allItems; // Return the accumulated items
  } catch (error) {
    console.error("Error fetching clothing items:", error);
    throw error;
  }
};

//SCROLLER
//*****************************************************************************************************************
export const fetchScrollerOne = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/scroller-one?populate=clothing_items`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching scroller-one data:", error);
    return null;
  }
};
