import React, { useState } from "react";
import MenuBar from "../components/menu-bar";
import FooterNewsletter from "../components/footer-newsletter";
import ItemsPageId from "./items-page-id";

const WishlistPage = () => {
  return (
    <>
      <MenuBar />
      <ItemsPageId type="wishlist" />
      <FooterNewsletter />
    </>
  );
};

export default WishlistPage;
