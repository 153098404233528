import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MenuBarAlternative from "../components/menu-bar"; // New component
import ItemCard from "../components/item-card";
import FooterNewsletter from "../components/footer-newsletter";
import Footer from "../components/footer";
import PageIndicator from "../components/page-indicator";
import FilterMenu from "../components/filter-menu";
import Separator2 from "../components/separator2";
import PropTypes from "prop-types";
import axios from "axios";

const Container = styled.div`
  display: flex;
  padding: 50px;
  max-width: 1600px;
  margin: 50px auto;
`;

const LeftMenu = styled.div`
  width: 500px;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

const RightContent = styled.div`
  flex-grow: 1;
`;

const ItemsGrid = styled.div`
  display: grid;
  gap: 20px;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 999px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CenteredTitle = styled.div`
  font-family: TimesNow-Book;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 40px;
  font-size: 24px;
  line-height: 26px;
`;

const CenteredText = styled.div`
  font-family: TimesNow-Book;
  text-align: center;
  margin: 0 auto 30px auto; /* Centering with auto margins */
  max-width: 750px; /* Control the maximum width */
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.15px;
`;

const ItemsPageId = ({ type }) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 30; // Customize the number of items per page
  const navigate = useNavigate();

  // Function to retrieve saved IDs from local storage based on type
  const getItemIds = () => {
    const items = JSON.parse(localStorage.getItem(type)) || []; // Fetches either wishlist or bag
    return items;
  };

  const loadItemsByIds = async (page) => {
    const itemIds = getItemIds(); // Get item IDs from local storage
    console.log(`Fetching items for ${type} IDs:`, itemIds); // Debugging
    if (!itemIds || itemIds.length === 0) {
      console.warn("No item IDs provided.");
      return;
    }

    try {
      // Fetch items by array of IDs
      const response = await axios.get(
        `https://strapi-production-7b58.up.railway.app/api/clothing-items?populate=*`,
        {
          params: {
            "pagination[page]": page,
            "pagination[pageSize]": itemsPerPage,
            "filters[id][$in]": itemIds, // Directly passing the array of item IDs
          },
        }
      );

      console.log("API response:", response); // Debugging

      const { data, meta } = response.data;

      if (data && data.length > 0) {
        const fetchedItems = data.map((item) => ({
          id: item.id,
          brand: item.attributes.brand,
          title: item.attributes.title,
          price: item.attributes.price,
          description: item.attributes.description,
          images:
            item.attributes.images?.data?.map(
              (image) => image.attributes.url
            ) || [],
        }));

        setItems(fetchedItems);
        setTotalItems(meta.pagination.total);
      } else {
        console.warn("No items found for the provided IDs.");
        setItems([]); // Clear items if no data is returned
      }
    } catch (error) {
      console.error("Error loading items by IDs:", error);
    }
  };

  useEffect(() => {
    loadItemsByIds(currentPage);
  }, [currentPage, type]); // Include type as a dependency to reload items when type changes

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemClick = (item) => {
    navigate(`/item-details/${item.id}`);
  };

  return (
    <>
      <Container>
        <RightContent>
          <ItemsGrid>
            {items.length > 0 ? (
              items.map((item) => (
                <ItemCard
                  key={item.id}
                  id={item.id}
                  images={item.images}
                  name={item.brand}
                  subtitle={item.title}
                  price={`$${item.price}`}
                  description={item.description}
                  onClick={() => handleItemClick(item)}
                />
              ))
            ) : (
              <p>No items available in your {type}.</p>
            )}
          </ItemsGrid>
        </RightContent>
      </Container>
      <PageIndicator
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};

ItemsPageId.propTypes = {
  type: PropTypes.oneOf(["wishlist", "bag"]).isRequired, // Restrict type to wishlist or bag
};

export default ItemsPageId;
