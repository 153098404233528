import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import MenuBarAlternative from "../components/menu-bar"; // New component
import ItemCard from "../components/item-card";
import FooterNewsletter from "../components/footer-newsletter";
import Footer from "../components/footer";
import PageIndicator from "../components/page-indicator";
import FilterMenu from "../components/filter-menu";
import Separator2 from "../components/separator2";
import PropTypes from "prop-types";
import axios from "axios";

const Container = styled.div`
  display: flex;
  padding: 50px;
  max-width: 1600px;
  margin: 50px auto;
`;

const LeftMenu = styled.div`
  width: 500px;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

const RightContent = styled.div`
  flex-grow: 1;
`;

const ItemsGrid = styled.div`
  display: grid;
  gap: 20px;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 999px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FilterSection = styled.div`
  font-family: PP Neue Montreal;
  margin-left: 10px;
  font-size: 12px;
  text-align: left;
  letter-spacing: 1.5px;
  height: 20px;
`;

const SORT_OPTIONS = {
  ALPHA_ASC: "ALPHABETICALLY, A-Z",
  ALPHA_DESC: "ALPHABETICALLY, Z-A",
  PRICE_ASC: "PRICE, LOW TO HIGH",
  PRICE_DESC: "PRICE, HIGH TO LOW",
  DATE_ASC: "DATE, OLD TO NEW",
  DATE_DESC: "DATE, NEW TO OLD",
};

const ItemsPage = ({ gender }) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortOption, setSortOption] = useState(SORT_OPTIONS.ALPHA_ASC);
  const itemsPerPage = 30;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const loadClothingItems = async (page) => {
    console.log("requesting page:" + page);
    try {
      const response = await axios.get(
        `https://strapi-production-7b58.up.railway.app/api/clothing-items?populate=*&filters[gender][$eq]=${gender}`,
        {
          params: {
            "pagination[page]": page,
            "pagination[pageSize]": itemsPerPage,
            ...(gender && { "filters[gender][$eq]": gender }),
          },
        }
      );
      const { data, meta } = response.data;
      let fetchedItems = data.map((item) => ({
        id: item.id,
        brand: item.attributes.brand,
        title: item.attributes.title,
        price: item.attributes.price,
        gender: item.attributes.gender,
        description: item.attributes.description,
        createdAt: item.attributes.createdAt,
        images:
          item.attributes.images?.data?.map((image) => image.attributes.url) ||
          [],
      }));

      // Sort the items based on the selected option
      fetchedItems = sortItems(fetchedItems, sortOption);

      setItems(fetchedItems);
      setTotalItems(meta.pagination.total);
    } catch (error) {
      console.error("Error loading clothing items:", error);
    }
  };

  const sortItems = (itemsToSort, option) => {
    const itemsCopy = [...itemsToSort];

    switch (option) {
      case SORT_OPTIONS.ALPHA_ASC:
        return itemsCopy.sort((a, b) => a.brand.localeCompare(b.brand));
      case SORT_OPTIONS.ALPHA_DESC:
        return itemsCopy.sort((a, b) => b.brand.localeCompare(a.brand));
      case SORT_OPTIONS.PRICE_ASC:
        return itemsCopy.sort((a, b) => a.price - b.price);
      case SORT_OPTIONS.PRICE_DESC:
        return itemsCopy.sort((a, b) => b.price - a.price);
      case SORT_OPTIONS.DATE_ASC:
        return itemsCopy.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      case SORT_OPTIONS.DATE_DESC:
        return itemsCopy.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      default:
        return itemsCopy;
    }
  };

  useEffect(() => {
    const pageFromUrl = searchParams.get("page");
    const pageToLoad = pageFromUrl ? parseInt(pageFromUrl, 10) : 1;
    setCurrentPage(pageToLoad);
    loadClothingItems(pageToLoad);
  }, [searchParams, gender, sortOption]); // Added sortOption as dependency

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchParams({ page: pageNumber });
  };

  const handleItemClick = (item) => {
    navigate(`/item-details/${item.id}`);
  };

  return (
    <>
      <MenuBarAlternative />
      <Container>
        <RightContent>
          <FilterSection>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>SORT</span>
              <select value={sortOption} onChange={handleSortChange}>
                {Object.values(SORT_OPTIONS).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </FilterSection>
          <ItemsGrid>
            {items.length > 0 ? (
              items.map((item) => (
                <ItemCard
                  key={item.id}
                  id={item.id}
                  images={item.images}
                  name={item.brand}
                  subtitle={item.title}
                  price={`$${item.price}`}
                  description={item.description}
                  onClick={() => handleItemClick(item)}
                />
              ))
            ) : (
              <p>No items available.</p>
            )}
          </ItemsGrid>
        </RightContent>
      </Container>
      <PageIndicator
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
      <FooterNewsletter />
    </>
  );
};

export default ItemsPage;
