import React, { useState } from "react";
import MenuBar from "../components/menu-bar";
import FooterNewsletter from "../components/footer-newsletter";
import BagItems from "../pages/bag-items";

const BagPage = () => {
  return (
    <>
      <MenuBar />
      <BagItems type="bag" />
      <FooterNewsletter />
    </>
  );
};

export default BagPage;
