import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import axios from "axios";
import { FaTrash } from "react-icons/fa"; // Import Font Awesome trash icon

const Container = styled.div`
  max-width: 1200px;
  margin: 50px auto;
  padding: 0 50px;
`;

const CartItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CartItem = styled.div`
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #eee;
  gap: 20px;
  align-items: center;
  position: relative;
`;

const ItemImage = styled.img`
  width: 120px;
  height: 160px;
  object-fit: cover;
`;

const ItemInfo = styled.div`
  text-align: left;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Brand = styled.h3`
  font-family: TimesNow-Book;
  font-size: 18px;
  margin: 0;
`;

const Title = styled.p`
  font-size: 15px;
  line-height: 20px;
  color: #666;
  margin: 0;
`;

const Price = styled.p`
  font-weight: 500;
  margin: 0;
  text-align: right;
`;

const TrashIcon = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 18px;
  color: red;

  &:hover {
    color: #333;
  }
`;

const Summary = styled.div`
  margin-top: 40px;
  padding: 20px;
  background-color: #f9f9f9;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 14px;

  &.total {
    color: green;
    font-size: 18px;
    font-weight: bold;
    border-top: 1px solid #ddd;
    margin-top: 20px;
    padding-top: 20px;
  }
`;

const EmptyCart = styled.p`
  text-align: center;
  padding: 40px;
  font-size: 16px;
  color: #666;
`;

const BagItems = ({ type }) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 30;
  const navigate = useNavigate();

  const getItemIds = () => {
    const items = JSON.parse(localStorage.getItem(type)) || [];
    return items;
  };

  const loadItemsByIds = async (page) => {
    const itemIds = getItemIds();
    if (!itemIds || itemIds.length === 0) {
      setItems([]); // Clear items when no IDs exist
      setTotalItems(0);
      return;
    }

    try {
      const response = await axios.get(
        `https://strapi-production-7b58.up.railway.app/api/clothing-items?populate=*`,
        {
          params: {
            "pagination[page]": page,
            "pagination[pageSize]": itemsPerPage,
            "filters[id][$in]": itemIds,
          },
        }
      );

      const { data, meta } = response.data;

      if (data && data.length > 0) {
        const fetchedItems = data.map((item) => ({
          id: item.id,
          brand: item.attributes.brand,
          title: item.attributes.title,
          price: item.attributes.price,
          description: item.attributes.description,
          images:
            item.attributes.images?.data?.map(
              (image) => image.attributes.url
            ) || [],
        }));

        setItems(fetchedItems);
        setTotalItems(meta.pagination.total);
      } else {
        setItems([]);
        setTotalItems(0);
      }
    } catch (error) {
      console.error("Error loading items by IDs:", error);
      setItems([]);
      setTotalItems(0);
    }
  };

  useEffect(() => {
    loadItemsByIds(currentPage);
  }, [currentPage, type]);

  const handleItemClick = (item) => {
    navigate(`/item-details/${item.id}`);
  };
  const removeItem = (id) => {
    // Retrieve the current list of item IDs from localStorage
    const itemIds = JSON.parse(localStorage.getItem(type)) || [];

    console.log("Current Item IDs before removal:", itemIds);
    console.log("Attempting to remove ID:", id);

    // Convert the id to a string if it's a number
    const idToRemove = id.toString();

    // Check if the item ID exists in localStorage
    if (itemIds.includes(idToRemove)) {
      // Filter out the ID of the item to be removed
      const updatedIds = itemIds.filter((itemId) => itemId !== idToRemove);

      console.log("Updated Item IDs after removal:", updatedIds);

      // Update localStorage with the new list of IDs
      localStorage.setItem(type, JSON.stringify(updatedIds));

      // Update the component state to remove the item from the displayed list
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
      setTotalItems(updatedIds.length);

      console.log("Item successfully removed from localStorage and UI.");
    } else {
      console.log("ID not found in localStorage; no action taken.");
    }
  };

  // Calculate totals
  const subtotal = items.reduce((sum, item) => sum + item.price, 0);
  const shipping = items.length > 0 ? 15 : 0; // Only add shipping if there are items
  const vat = subtotal * 0.2;
  const total = subtotal + shipping + vat;

  return (
    <Container>
      <CartItemList>
        {items.length > 0 ? (
          <>
            {items.map((item) => (
              <CartItem key={item.id}>
                <ItemImage
                  src={item.images[0]}
                  alt={item.title}
                  onClick={() => handleItemClick(item)}
                  style={{ cursor: "pointer" }}
                />
                <ItemInfo>
                  <ItemDetails>
                    <Brand>{item.brand}</Brand>
                    <Title>{item.title}</Title>
                    <Title>{item.description}</Title>
                  </ItemDetails>
                  <Price>${item.price}</Price>
                </ItemInfo>
                <TrashIcon onClick={() => removeItem(item.id)}>
                  <FaTrash />
                </TrashIcon>
              </CartItem>
            ))}

            <Summary>
              <SummaryRow>
                <span>Subtotal</span>
                <span>${subtotal.toFixed(2)}</span>
              </SummaryRow>
              <SummaryRow>
                <span>Shipping</span>
                <span>${shipping.toFixed(2)}</span>
              </SummaryRow>
              <SummaryRow>
                <span>VAT (20%)</span>
                <span>${vat.toFixed(2)}</span>
              </SummaryRow>
              <SummaryRow className="total">
                <span>Total</span>
                <span>${total.toFixed(2)}</span>
              </SummaryRow>
            </Summary>
          </>
        ) : (
          <EmptyCart>No items available in your {type}.</EmptyCart>
        )}
      </CartItemList>
    </Container>
  );
};

BagItems.propTypes = {
  type: PropTypes.oneOf(["wishlist", "bag"]).isRequired,
};

export default BagItems;
