import React, { useState } from "react";
import styled from "styled-components";

const FilterContainer = styled.div`
  padding: 20px;

  max-width: 340px;
`;

const FilterSection = styled.div`
  margin-bottom: 20px;
`;

const FilterTitle = styled.h3`
  font-family: PPNeueMontreal-Medium;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
`;

const FilterOptions = styled.div`
  padding: ${({ open }) => (open ? "10px 0" : "0")};
  max-height: ${({ open }) => (open ? "300px" : "0")}; // Smooth expansion
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease; // Smooth transition
`;

const FilterOption = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const FilterCheckbox = styled.input`
  margin-right: 10px;
`;

const DropdownIcon = styled.span`
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
`;

const FilterMenu = () => {
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [colorOpen, setColorOpen] = useState(false);
  const [designerOpen, setDesignerOpen] = useState(false);
  const [beltSizeOpen, setBeltSizeOpen] = useState(false);
  const [clothingSizeOpen, setClothingSizeOpen] = useState(false);

  const [category, setCategory] = useState("All");
  const [color, setColor] = useState("All");
  const [designer, setDesigner] = useState("All");

  const toggleSection = (section) => {
    switch (section) {
      case "category":
        setCategoryOpen(!categoryOpen);
        break;
      case "color":
        setColorOpen(!colorOpen);
        break;
      case "designer":
        setDesignerOpen(!designerOpen);
        break;
      case "beltSize":
        setBeltSizeOpen(!beltSizeOpen);
        break;
      case "clothingSize":
        setClothingSizeOpen(!clothingSizeOpen);
        break;
      default:
        break;
    }
  };

  return (
    <FilterContainer>
      {/* Category Section */}
      <FilterSection>
        <FilterTitle onClick={() => toggleSection("category")}>
          CATEGORY
          <DropdownIcon open={categoryOpen}>▼</DropdownIcon>
        </FilterTitle>
        <FilterOptions open={categoryOpen}>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="category"
              checked={category === "All"}
              onChange={() => setCategory("All")}
            />
            All
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="category"
              checked={category === "Clothing"}
              onChange={() => setCategory("Clothing")}
            />
            Clothing
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="category"
              checked={category === "Shoes"}
              onChange={() => setCategory("Shoes")}
            />
            Shoes
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="category"
              checked={category === "Accessories"}
              onChange={() => setCategory("Accessories")}
            />
            Accessories
          </FilterOption>
        </FilterOptions>
      </FilterSection>

      {/* Colour Section */}
      <FilterSection>
        <FilterTitle onClick={() => toggleSection("color")}>
          COLOR
          <DropdownIcon open={colorOpen}>▼</DropdownIcon>
        </FilterTitle>
        <FilterOptions open={colorOpen}>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="color"
              checked={color === "All"}
              onChange={() => setColor("All")}
            />
            All
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="color"
              checked={color === "Red"}
              onChange={() => setColor("Red")}
            />
            Red
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="color"
              checked={color === "Blue"}
              onChange={() => setColor("Blue")}
            />
            Blue
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="color"
              checked={color === "Black"}
              onChange={() => setColor("Black")}
            />
            Black
          </FilterOption>
        </FilterOptions>
      </FilterSection>

      {/* Designer Section */}
      <FilterSection>
        <FilterTitle onClick={() => toggleSection("designer")}>
          DESIGNER
          <DropdownIcon open={designerOpen}>▼</DropdownIcon>
        </FilterTitle>
        <FilterOptions open={designerOpen}>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="designer"
              checked={designer === "All"}
              onChange={() => setDesigner("All")}
            />
            All
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="designer"
              checked={designer === "Designer 1"}
              onChange={() => setDesigner("Designer 1")}
            />
            Designer 1
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="designer"
              checked={designer === "Designer 2"}
              onChange={() => setDesigner("Designer 2")}
            />
            Designer 2
          </FilterOption>
        </FilterOptions>
      </FilterSection>

      {/* Belt Size Section */}
      <FilterSection>
        <FilterTitle onClick={() => toggleSection("beltSize")}>
          BELT SIZE
          <DropdownIcon open={beltSizeOpen}>▼</DropdownIcon>
        </FilterTitle>
        <FilterOptions open={beltSizeOpen}>
          <FilterOption>
            <FilterCheckbox type="radio" name="beltSize" onChange={() => {}} />
            All
          </FilterOption>
          <FilterOption>
            <FilterCheckbox type="radio" name="beltSize" onChange={() => {}} />
            Small
          </FilterOption>
          <FilterOption>
            <FilterCheckbox type="radio" name="beltSize" onChange={() => {}} />
            Medium
          </FilterOption>
          <FilterOption>
            <FilterCheckbox type="radio" name="beltSize" onChange={() => {}} />
            Large
          </FilterOption>
        </FilterOptions>
      </FilterSection>

      {/* Clothing Size Section */}
      <FilterSection>
        <FilterTitle onClick={() => toggleSection("clothingSize")}>
          CLOTHING SIZE
          <DropdownIcon open={clothingSizeOpen}>▼</DropdownIcon>
        </FilterTitle>
        <FilterOptions open={clothingSizeOpen}>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="clothingSize"
              onChange={() => {}}
            />
            All
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="clothingSize"
              onChange={() => {}}
            />
            XS
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="clothingSize"
              onChange={() => {}}
            />
            S
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="clothingSize"
              onChange={() => {}}
            />
            M
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="clothingSize"
              onChange={() => {}}
            />
            L
          </FilterOption>
          <FilterOption>
            <FilterCheckbox
              type="radio"
              name="clothingSize"
              onChange={() => {}}
            />
            XL
          </FilterOption>
        </FilterOptions>
      </FilterSection>
    </FilterContainer>
  );
};

export default FilterMenu;
