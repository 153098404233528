import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const Wrapper = styled.div`
  max-width: 1500px; /* Prevent the container from exceeding 1500px */
  margin: 0 auto; /* Center the container */
  padding: 0 50px; /* Maintain 50px padding on both sides */
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Column = styled.div`
  width: calc(16.666% - 20px); /* 6 columns with some space between */
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    width: calc(33.333% - 20px); /* 3 columns on medium screens */
  }

  @media (max-width: 768px) {
    width: calc(50% - 15px); /* 2 columns on small screens */
  }

  @media (max-width: 480px) {
    width: 100%; /* 1 column on very small screens */
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the text with the image */
`;

const Image = styled.img`
  width: 190px;
  height: 260px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  font-size: 14px;
  margin: 0 0 0px 0;
  letter-spacing: 3px;
  text-align: left;
  width: 190px; /* Ensure the text width matches the image width */
  color: grey;
`;

const Subtitle = styled.p`
  font-family: Times Now;
  font-size: 17px;
  line-height: 24px;
  margin-top: 5;
  color: black;
  text-align: left;
  width: 190px; /* Ensure the text width matches the image width */
`;

const SixColumnLayout = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Fetch data from Strapi
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://strapi-production-7b58.up.railway.app/api/blog-entries?populate=*"
        );
        const data = response.data.data;

        // Map the fetched data to the format the component expects
        const formattedItems = data.map((entry) => ({
          id: entry.id,
          title: entry.attributes.category,
          subtitle: entry.attributes.description,
          image: entry.attributes.image?.data?.attributes?.url,
        }));

        setItems(formattedItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Wrapper>
      <Container>
        {items.map((item) => (
          <Column key={item.id}>
            <ImageContainer>
              <Image src={item.image} alt={item.title} />
              <Title>{item.title}</Title>
              <Subtitle>{item.subtitle}</Subtitle>
            </ImageContainer>
          </Column>
        ))}
      </Container>
    </Wrapper>
  );
};

export default SixColumnLayout;
