import React, { useRef } from "react";

const cardStyles = {
  container: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "10px",
    margin: "0 5px",
    minWidth: "100px",
    maxWidth: "100px",
    textAlign: "left",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  imageContainer: {
    width: "100%",
    height: "120px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  textContainer: {
    paddingTop: "10px",
  },
  title: {
    fontSize: "10px",
    margin: "5px 0",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  brand: {
    fontSize: "12px",
    color: "black",
    margin: "2px 0",
  },
  price: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#333",
    margin: "2px 0",
  },
};

const scrollStyles = {
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  scrollContainer: {
    display: "flex",
    overflowX: "scroll",
    scrollbarWidth: "none",
    position: "relative",
    maskImage:
      "linear-gradient(to right, transparent, black 3%, black 97%, transparent)",
    WebkitMaskImage:
      "linear-gradient(to right, transparent, black 3%, black 97%, transparent)",
  },
  hideScrollbar: {
    display: "none",
  },
  scrollButton: {
    background: "none",
    border: "none",
    fontSize: "24px",
    color: "#333",
    cursor: "pointer",
    padding: "0 10px",
  },
};

const Card = ({ image, name, brand, price, onClick }) => (
  <div style={cardStyles.container} onClick={onClick}>
    <div>
      <img src={image} alt="Card" style={cardStyles.imageContainer} />
    </div>
    <div style={cardStyles.textContainer}>
      <p style={cardStyles.brand}>{brand}</p>
      <p style={cardStyles.price}>{price}</p>
    </div>
  </div>
);

const HorizontalScroller = ({ cards }) => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 200,
      behavior: "smooth",
    });
  };

  const handleCardClick = (itemId) => {
    window.location.href = `/item-details/${itemId}`;
  };

  return (
    <div style={scrollStyles.container}>
      <button style={scrollStyles.scrollButton} onClick={scrollLeft}>
        &lsaquo;
      </button>
      <div style={scrollStyles.scrollContainer} ref={scrollContainerRef}>
        {cards.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            name={card.name}
            brand={card.brand}
            price={card.price}
            onClick={() => handleCardClick(card.id)}
          />
        ))}
      </div>
      <button style={scrollStyles.scrollButton} onClick={scrollRight}>
        &rsaquo;
      </button>
    </div>
  );
};

export default HorizontalScroller;
