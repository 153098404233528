import React from "react";
import styled from "styled-components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Container for the page indicator bar
const BarContainer = styled.div`
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Wrapper for the page indicator
const PageIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px; /* Increased space between elements */
`;

// Arrow buttons with "Previous" and "Next"
const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 5px; /* Space between icon and text */

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

// Page numbers
const PageNumber = styled.span`
  font-size: 16px;
  color: ${(props) => (props.isCurrent ? "black" : "grey")};
  font-weight: ${(props) => (props.isCurrent ? "bold" : "normal")};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const PageIndicator = ({ totalPages, currentPage, onPageChange }) => {
  const getPageNumbers = () => {
    const pages = [];

    // Always show the first page
    pages.push(
      <PageNumber
        key={1}
        isCurrent={currentPage === 1}
        onClick={() => onPageChange(1)}
      >
        1
      </PageNumber>
    );

    // Add dots if currentPage is beyond page 4
    if (currentPage > 4) {
      pages.push(<span key="start-dots">...</span>);
    }

    // Define range of visible page numbers
    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    // Show the pages within the range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PageNumber
          key={i}
          isCurrent={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </PageNumber>
      );
    }

    // Add dots before the last page if currentPage is far from the end
    if (currentPage < totalPages - 3) {
      pages.push(<span key="end-dots">...</span>);
    }

    // Always show the last page
    pages.push(
      <PageNumber
        key={totalPages}
        isCurrent={currentPage === totalPages}
        onClick={() => onPageChange(totalPages)}
      >
        {totalPages}
      </PageNumber>
    );

    return pages;
  };

  return (
    <BarContainer>
      <PageIndicatorContainer>
        {/* Left Arrow with "Previous" */}
        <ArrowButton
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaChevronLeft />
          <span>PREVIOUS</span>
        </ArrowButton>

        {/* Page Numbers */}
        {getPageNumbers()}

        {/* Right Arrow with "Next" */}
        <ArrowButton
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <span>NEXT</span>
          <FaChevronRight />
        </ArrowButton>
      </PageIndicatorContainer>
    </BarContainer>
  );
};

export default PageIndicator;
