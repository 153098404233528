import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { FaHeart, FaRegHeart } from "react-icons/fa";

// Styles for individual item cards
const Card = styled.div`
  padding: 10px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1.2;
  overflow: hidden;
  position: relative;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isHovered ? 1 : 0)};
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  letter-spacing: 1px;
  margin: 20px 0 5px 0;
  cursor: pointer;
`;

const ItemSubtitle = styled.p`
  font-family: TimesNow;
  font-size: 16px;
  color: #777;
  margin: 0px;
`;

const ItemPrice = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  color: black;
`;

const WishlistIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  font-size: 20px;
  z-index: 2;
  cursor: pointer;
  &:hover {
    color: darkred;
  }
`;

const ItemCard = ({ images, name, subtitle, price, description, id }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const navigate = useNavigate();

  const mainImage = images?.[0] || "https://via.placeholder.com/200x300";
  const hoverImage = images?.[1] || mainImage;

  // Check local storage for wishlist status on component load
  useEffect(() => {
    const currentWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    setIsWishlisted(currentWishlist.includes(id.toString()));
  }, [id]);

  const handleNavigation = () => {
    navigate(`/item-details/${id}`, {
      state: { id, name, subtitle, price, images, description },
    });
  };

  const toggleWishlist = () => {
    const currentWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    let updatedWishlist;

    if (isWishlisted) {
      updatedWishlist = currentWishlist.filter(
        (itemId) => itemId !== id.toString()
      );
    } else {
      updatedWishlist = [...currentWishlist, id.toString()];
    }

    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
    setIsWishlisted(!isWishlisted);
  };

  return (
    <Card onClick={handleNavigation}>
      <ImageContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ItemImage src={mainImage} alt={name} isHovered={!isHovered} />
        <ItemImage src={hoverImage} alt={name} isHovered={isHovered} />

        {/* Heart Icon */}
        <WishlistIcon
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering handleNavigation
            toggleWishlist();
          }}
        >
          {isWishlisted ? <FaHeart /> : <FaRegHeart />}
        </WishlistIcon>
      </ImageContainer>
      <ItemTitle>{name}</ItemTitle>
      <ItemSubtitle>{subtitle}</ItemSubtitle>
      <ItemPrice>{price}</ItemPrice>
    </Card>
  );
};

export default ItemCard;
