import React from "react";
import HeroImage from "../components/hero-image";
import OfferSection from "../components/offer-section";
import OfferSectionWomen from "../components/offer-section-women";
import TwoImagesLayout from "../components/two-images-layout";
import Separator from "../components/separator";
import JournalEntry from "../components/journal-entry";
import SixColumnLayout from "../components/six-column-layout";
import ThreeColumnLayout from "../components/three-column-layout";
import FooterNewsletter from "../components/footer-newsletter";
import Disclaimer from "../components/disclaimer";
import MenuBarAlternative from "../components/menu-bar";
import BigProductCarousel from "../components/big-product-carousel";

function HomePage() {
  return (
    <>
      <Disclaimer />
      <MenuBarAlternative />
      <HeroImage />
      <OfferSection />
      <Separator />
      <TwoImagesLayout />
      <Separator />
      <OfferSectionWomen />
      <Separator />
      {/* <SmallImagesLayout /> */}
      {/* <Separator /> */}
      <JournalEntry />
      <Separator />
      {/* <SixColumnLayout />
      <Separator /> */}
      {/* <ThreeColumnLayout /> */}
      <BigProductCarousel
        dataEndpoint={
          "https://strapi-production-7b58.up.railway.app/api/scroller-three?populate[clothing_items][populate]=images"
        }
      />
      <FooterNewsletter />
      {/* <Footer /> */}
    </>
  );
}

export default HomePage;
