import React from "react";
import omiAvatar from "../../assets/chat/omi_avatar.svg";
import meAvatar from "../../assets/chat/me_avatar.svg";
import { Avatar, MessageBox } from "react-chat-elements";
import styled from "styled-components";

const MessageItem = ({ message }) => {
  const avatar = message.position === "left" ? omiAvatar : meAvatar; // Change avatars based on position

  // Check if message.text is an object and handle accordingly
  const isObjectMessage =
    typeof message.text === "object" && message.text !== null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        margin: "10px 0",
        justifyContent:
          message.position === "right" ? "flex-end" : "flex-start",
      }}
    >
      {message.position === "left" && <Avatar src={avatar} />}

      {isObjectMessage ? (
        // Render item or specific component for object message
        <div>
          {/* You can customize how you render item messages here */}
          <p>Item: {JSON.stringify(message.text)}</p>{" "}
          {/* Adjust this line to format the item appropriately */}
        </div>
      ) : (
        <MessageBox
          position={message.position}
          type={message.type}
          text={message.text}
          date={message.date}
        />
      )}

      {message.position === "right" && <Avatar src={avatar} />}
    </div>
  );
};

export default MessageItem;
