import React from "react";
import styled from "styled-components";

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const GreyLine = styled.div`
  width: 100%;
  max-width: 1500px;
  height: 1px;
  background-color: #ddd;
`;

const Separator = () => {
  return (
    <LineContainer>
      <GreyLine />
    </LineContainer>
  );
};

export default Separator;
